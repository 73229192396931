/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: webapp-contrib
 * Version: 7.1.2
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
window.LOCALE_BO = {
  supprimer: "Supprimer",
  annuler: "Annuler",
  ajouter: "Ajouter",
  creer: "Création",
  editer: "Annuler",
  valider: "Valider",
  archiver: "Archiver",
  consulter: "Consulter",
  modifier: "Modifier",
  copy: "Copier",
  nouvelleFenetre: "(Nouvelle fenêtre)",
  selectionner: "Sélectionner",
  confirmer: "Confirmer",
  confirmerQuitter: "Toutes les modifications non enregistrées seront perdues, souhaitez-vous continuer ?",
  fermer: "Non",
  open : "Ouvrir",
  close: "Fermer",
  erreur: "Erreur",
  ok: "Oui",
  confirmQuitterPage: "Êtes-vous sûr de vouloir quitter cette page&nbsp;?",
  confirmSupprFiche: "La suppression de cette fiche peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir supprimer cette fiche&nbsp;?",
  selectionnerUnELement: "Veuillez sélectionner au moins un élément avant de lancer une action.",
  confirmSupprFiches: "La suppression de ces fiches peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir supprimer ces fiches&nbsp;?",
  confirmSupprUtilisateur: "Êtes-vous sûr de vouloir supprimer cet utilisateur&nbsp;?",
  confirmSupprUtilisateurs: "Êtes-vous sûr de vouloir supprimer ces utilisateurs&nbsp;?",
  confirmSupprGroupe: "Êtes-vous sûr de vouloir supprimer ce groupe&nbsp;?",
  confirmSupprRubrique: "Êtes-vous certain de vouloir supprimer la rubrique &quot;{0}&quot; ainsi que son arborescence et tous ses contenus ? Attention, seules les fiches et leurs plugins vont être supprimés.<br/>Cette action est définitive.",
  confirmSupprLibelle: "Êtes-vous sûr de vouloir supprimer ce libellé&nbsp;?",
  confirmArchFiches: "L'archivage de ces fiches peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir archiver ces fiches&nbsp;?<br>(Seules les fiches en ligne seront archivées.)",
  lExtension: "L'extension",
  leModule: "Le module",
  patienter: "<br/>Merci de bien vouloir patienter&hellip;",
  voirEnLigne: "Voir en ligne",
  actions: "Actions",
  erreurRecherche: "Une erreur s'est produite lors du calcul de vos résultats de recherche.",
  erreurActionMasse: "Une erreur s'est produite lors du traitement de votre action.",
  confirmationActionMasseSuppr: "Les fiches sélectionnées ont bien été supprimées.",
  confirmationActionMasseArchi: "Les fiches &quot;En ligne&quot; ont bien été archivées.",
  confirmationActionMasseUtilisateur: "Les utilisateurs sélectionnés ont bien été supprimés.",
  activer: "Activer",
  desactiver: "Désactiver",
  selectall: "Tout sélectionner",
  selectnone: "Tout désélectionner",
  sectionsImpacts: "La modification de la rubrique va impacter %d URL, souhaitez-vous continuer ?",
  confirmSupprUrlRedirect: "Êtes-vous sûr de vouloir supprimer cette url de redirection&nbsp;?",
  pagesImpacts: "La modification de la fiche va impacter %d URL, souhaitez-vous continuer ?",
  siteImpacts: "Vos modifications pourraient impacter un grand nombre d'URL, souhaitez-vous continuer ?",
  headPageImpact: "La fiche est page d'accueil d'une rubrique. L'archivage de cette fiche va impacter les URL de la rubrique et de ses contenus. Souhaitez-vous continuer ?",
  headPageImpactDelete: "La fiche est page d'accueil d'une rubrique. La suppression de cette fiche va impacter les URL de la rubrique et de ses contenus. Souhaitez-vous continuer ?",
  mandatorySection: "La zone Rubrique est obligatoire",
  previewError: "Erreur de prévisualisation : {errMsg}",
  unexpectedPreviewError: "Erreur inattendue lors de la prévisualisation, réponse du serveur : {errMsg}",

  // Etats
  enCoursSuppression: "est en cours de suppression.",
  enCoursRechargement: "Le rechargement est en cours.",
  enSuccesSuppression: "La suppression a bien été effectuée",
  enErreurSuppression: "Une erreur est survenue lors de la suppression",
  enCoursdActivation: "est en cours d'activation.",
  enSuccesActivation: "L'activation a bien été effectuée",
  enErreurActivation: "Une erreur est survenue lors de l'activation",
  enCoursdeDesactivation: "est en cours de désactivation.",
  enSuccesDesactivation: "La désactivation a bien été effectuée",
  enErreurDesactivation: "Une erreur est survenue lors de la désactivation",
  enSuccesRechargement: "Le rechargement a bien été effectué",
  enErreurRechargement: "Une erreur est survenue lors du rechargement",
  enSucces: "Succès",
  enErreur: "Erreur",
  jobSiteInProgress: "Processus en cours",

  parcourir: "Cliquer sur parcourir",

  select: {
    undefined: "Indéfini(e)"
  },

  libelle: {
    inconnu: "Libellé inconnu"
  },

  viewTools: {
    switchView: "Changer de vue",
    treeView: "Arborescence",
    tableView: "Table"
  },

  kselect: {
    mono: {
      select: "Sélectionner",
      clear: "Effacer"
    },
    multi: {
      parentLabel: "Item(s) disponible(s)",
      compositionLabel: "Item(s) sélectionné(s)"
    }
  },

  // Validations
  validationMessages: {
    caracteres_autorises: "caractères autorisés",
    ksupDate: "Veuillez fournir une date valide",
    ksupPhone: "Veuillez fournir un numéro de téléphone valide",
    ksupUrl: "Veuillez fournir une url valide",
    ckeditor: "La saisie ne doit pas dépasser {0} caractères.",
    pwdVerdicts: ["Faible", "Normal", "Moyen", "Fort", "Très fort"],
    pwdErrorMessages: {
      password_too_short: "Trop court",
      email_as_password: "N'utilisez pas votre email comme mot de passe",
      same_as_username: "Votre mot de passe ne doit pas contenir votre identifiant",
      two_character_classes: "Variez la casse",
      repeated_character: "Trop de répétitions",
      sequence_found: "Votre mot de passe contient des séquences"
    }
  },

  // Services
  services: {
    arbre: {
      indisponible: "Le service de gestion d'arborescence est actuellement indisponible. Aucune modification ne sera prise en compte.",
      noSearchResult: "Aucun résultat de recherche",
      confirmerSuppression: "Êtes-vous sûr de vouloir supprimer {0} ?",
      confirmerDrop: "La modification de la rubrique pourrait impacter un grand nombre d'URL, souhaitez-vous continuer ?"
    },
    auto_complete: {
      search: "Recherche en cours&hellip;",
      noresult: "Pas de résultat"
    }
  },

  // Popins
  popin: {
    title: {
      generique: "Veuillez sélectionner un élément",
      structure: {
        mono: "Sélectionner une structure",
        multi: "Sélectionner des structures"
      },
      rubrique: {
        mono: "Sélectionner une rubrique",
        multi: "Sélectionner des rubriques"
      },
      groupe: {
        mono: "Sélectionner un groupe",
        multi: "Sélectionner des groupes"
      },
      utilisateur: "Sélectionner un utilisateur",
      objet: "Sélectionner une fiche",
      pagelibre: "",
      photo: "Ajouter une image",
      fichier: "Ajouter un fichier"
    },
    errorFile: "La suppression de ce message provoquera également la suppression de la sauvegarde en erreur. Êtes vous certain de vouloir supprimer ce message ?"
  },

  //Médias
  media: {
    actions: {
      "delete": {
        confirm: "Supprimer définitivement la ressource ?"
      }
    },
    player: {
      mute: "Activer/Désactiver le son",
      playPause: "Lire/Mettre en pause",
      fullscreen: "Plein écran",
      tracks: "Sous-titres",
      postRoll: "Fermer"
    }
  },

  //Layout
  layout: {
    confirm: {
      title: "Changer de gabarit d'affichage ?",
      "continue": "Continuer",
      cancel: "Annuler"
    }
  },

  supervision: {
    settings: {
      title: "Configuration du traitement"
    }
  },

  userFront: {
    title: "Refuser"
  },
  validation : {
    titre : "Sélection du niveau d'approbation",
    niveauAuto : "Votre fiche passera automatiquement dans l'état suivant :",
    delegation : "Vous pouvez déléguer vos droits de validation sur l'un des niveaux suivants :"
  },
  // CKEditor
  ckeditor: {
    link: {
      section: {
        contextual: "Contextuelle"
      }
    },
    anchor: {
      alert: "Le nom doit commencer par une lettre et ne doit contenir que les caractères suivant : A-Z, a-z, 0-9, :-_."
    },
    notifications: {
      spellcheker: "La correction orthographique est accessible via <kbd title=&quot;Controle&quot;>Ctrl</kbd> + clic droit"
    },
    plugins: {
      listeFiche: {
        title: "Liste de fiches",
        insert: "Insérer une liste de fiches"
      },
      link: {
        title: "Insérer un lien",
        anchor: {
          tip: {
            alert: "Aucune ancre n'a été détectée dans la page en cours d'édition."
          }
        }
      },
      kflipbook: {
        title: "Insérer un Flipbook",
        menu: "Propriétés du Flipbook"
      },
      kimage: {
        title: "Insérer une image",
        menu: "Propriétés de l'image",
        erreur_legende: "La légende est obligatoire."
      },
      kvideo: {
        title: "Insérer une vidéo",
        menu: "Propriétés de la vidéo"
      },
      kaudio: {
        title: "Insérer de l'audio",
        menu: "Propriétés de l'audio"
      },
      kflash: {
        title: "Insérer une animation Flash",
        menu: "Propriétés de l'animation Flash"
      },
      kpdfviewer: {
        title: "Insérer un PDF",
        menu: "Propriétés du PDF"
      },
      ktag: {
        title: "Tags",
        menu: "Propriétés du tag"
      }
    }
  },
  tree:{
    selectChildren: "Sélectionner les enfants",
    unselectChildren: "Désélectionner les enfants",
    add: "Ajouter",
    copy: "Copier",
    delete: "Supprimer",
    edit: "Éditer"
  }
};
